(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/socket/assets/javascripts/expose.js') >= 0) return;  svs.modules.push('/components/core/socket/assets/javascripts/expose.js');
"use strict";

if (svs.core.detect.feature('ft-new-socket-impl')) {
  svs.core.Socket = svs.core.SocketNew;
} else {
  svs.core.Socket = svs.core.SocketOld;
}
svs.core.socket = new svs.core.Socket(svs.core.socket.data);

 })(window);