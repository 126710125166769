(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/socket/assets/javascripts/socket2.js') >= 0) return;  svs.modules.push('/components/core/socket/assets/javascripts/socket2.js');
"use strict";

function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _classPrivateFieldSet(s, a, r) { return s.set(_assertClassBrand(s, a), r), r; }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }
const log = new svs.core.log.Logger('svs.core.Socket2');
const socketIO = svs.core.config.data.svsconfig.socketIO;

var _data = new WeakMap();
var _socket = new WeakMap();
var _transportType = new WeakMap();
var _socketId = new WeakMap();
var _subscriptions = new WeakMap();
var _eventCallbacks = new WeakMap();
var _setupListeners = new WeakMap();
var _connect = new WeakMap();
var _emitSubscriptions = new WeakMap();
var _runSubscriptionsCallback = new WeakMap();
var _runCallbacksForEvent = new WeakMap();
class Socket {
  constructor() {
    var _this = this;
    let _data2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    _classPrivateFieldInitSpec(this, _data, null);
    _classPrivateFieldInitSpec(this, _socket, null);
    _classPrivateFieldInitSpec(this, _transportType, null);
    _classPrivateFieldInitSpec(this, _socketId, null);
    _classPrivateFieldInitSpec(this, _subscriptions, []);
    _classPrivateFieldInitSpec(this, _eventCallbacks, []);
    _classPrivateFieldInitSpec(this, _setupListeners, () => {
      window.addEventListener('beforeunload', () => {
        if (this.isConnected()) {
          _classPrivateFieldGet(_socket, this).off();
          _classPrivateFieldGet(_socket, this).disconnect();
        }
      }, {
        once: true
      });

      _classPrivateFieldGet(_socket, this).onAnyOutgoing(function (eventName) {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }
        log.debug("[".concat(eventName, "]"), args, {
          metadata: {
            socket: _classPrivateFieldGet(_socketId, _this),
            transType: _classPrivateFieldGet(_transportType, _this)
          }
        });
        _classPrivateFieldGet(_runCallbacksForEvent, _this).call(_this, eventName, args);
      });

      _classPrivateFieldGet(_socket, this).onAny(function (eventName) {
        for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
          args[_key2 - 1] = arguments[_key2];
        }
        log.debug("[".concat(eventName, "]"), args, {
          metadata: {
            socket: _classPrivateFieldGet(_socketId, _this),
            transType: _classPrivateFieldGet(_transportType, _this)
          }
        });
        _classPrivateFieldGet(_runCallbacksForEvent, _this).call(_this, eventName, args);
      });


      _classPrivateFieldGet(_socket, this).on('connect', function () {
        const engine = _classPrivateFieldGet(_socket, _this).io.engine;
        _classPrivateFieldSet(_transportType, _this, engine.transport.name);
        _classPrivateFieldSet(_socketId, _this, _classPrivateFieldGet(_socket, _this).id);
        log.info('[connect]', {
          metadata: {
            socket: _classPrivateFieldGet(_socketId, _this),
            transType: _classPrivateFieldGet(_transportType, _this)
          }
        });
        _classPrivateFieldGet(_emitSubscriptions, _this).call(_this, _classPrivateFieldGet(_subscriptions, _this));
        for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
          args[_key3] = arguments[_key3];
        }
        _classPrivateFieldGet(_runCallbacksForEvent, _this).call(_this, 'connect', args);


        engine.off('upgrade');

        engine.on('upgrade', () => {
          _classPrivateFieldSet(_transportType, _this, engine.transport.name);
          _classPrivateFieldSet(_socketId, _this, _classPrivateFieldGet(_socket, _this).id);
          log.info('[upgrade]', {
            metadata: {
              socket: _classPrivateFieldGet(_socketId, _this),
              transType: _classPrivateFieldGet(_transportType, _this)
            }
          });
        });
      });

      _classPrivateFieldGet(_socket, this).on('disconnect', function (reason, details) {
        log.info('[disconnect]', {
          metadata: {
            socket: _classPrivateFieldGet(_socketId, _this),
            transType: _classPrivateFieldGet(_transportType, _this),
            reason,
            details
          }
        });
        for (var _len4 = arguments.length, args = new Array(_len4 > 2 ? _len4 - 2 : 0), _key4 = 2; _key4 < _len4; _key4++) {
          args[_key4 - 2] = arguments[_key4];
        }
        _classPrivateFieldGet(_runCallbacksForEvent, _this).call(_this, 'disconnect', [reason, details, ...args]);
      });

      _classPrivateFieldGet(_socket, this).on('connect_error', function (error) {
        const engine = _classPrivateFieldGet(_socket, _this).io.engine;
        _classPrivateFieldSet(_transportType, _this, engine.transport.name);

        if (_classPrivateFieldGet(_socket, _this).active) {
          log.info('[connect_error]: socket will try to reconnect', error, {
            metadata: {
              socket: _classPrivateFieldGet(_socketId, _this),
              transType: _classPrivateFieldGet(_transportType, _this)
            }
          });
          return;
        }

        log.error('[connect_error]: socket is dead', error, {
          metadata: {
            socket: _classPrivateFieldGet(_socketId, _this),
            transType: _classPrivateFieldGet(_transportType, _this)
          }
        });
        for (var _len5 = arguments.length, args = new Array(_len5 > 1 ? _len5 - 1 : 0), _key5 = 1; _key5 < _len5; _key5++) {
          args[_key5 - 1] = arguments[_key5];
        }
        _classPrivateFieldGet(_runCallbacksForEvent, _this).call(_this, 'connect_error', [error, ...args]);

        _classPrivateFieldGet(_runCallbacksForEvent, _this).call(_this, 'dead', [{}]);
      });


      _classPrivateFieldGet(_socket, this).io.on('reconnect', function (attempt) {
        log.info('[reconnect]', {
          metadata: {
            socket: _classPrivateFieldGet(_socketId, _this),
            transType: _classPrivateFieldGet(_transportType, _this),
            attempt
          }
        });
        for (var _len6 = arguments.length, args = new Array(_len6 > 1 ? _len6 - 1 : 0), _key6 = 1; _key6 < _len6; _key6++) {
          args[_key6 - 1] = arguments[_key6];
        }
        _classPrivateFieldGet(_runCallbacksForEvent, _this).call(_this, 'reconnect', [attempt, ...args]);
      });

      _classPrivateFieldGet(_socket, this).io.on('reconnect_attempt', function (attempt) {
        log.debug('[reconnect_attempt]', {
          metadata: {
            socket: _classPrivateFieldGet(_socketId, _this),
            transType: _classPrivateFieldGet(_transportType, _this),
            attempt
          }
        });
        for (var _len7 = arguments.length, args = new Array(_len7 > 1 ? _len7 - 1 : 0), _key7 = 1; _key7 < _len7; _key7++) {
          args[_key7 - 1] = arguments[_key7];
        }
        _classPrivateFieldGet(_runCallbacksForEvent, _this).call(_this, 'reconnect_attempt', [attempt, ...args]);
      });

      _classPrivateFieldGet(_socket, this).io.on('reconnect_error', function (error) {
        log.debug('[reconnect_error]', error, {
          metadata: {
            socket: _classPrivateFieldGet(_socketId, _this),
            transType: _classPrivateFieldGet(_transportType, _this)
          }
        });
        for (var _len8 = arguments.length, args = new Array(_len8 > 1 ? _len8 - 1 : 0), _key8 = 1; _key8 < _len8; _key8++) {
          args[_key8 - 1] = arguments[_key8];
        }
        _classPrivateFieldGet(_runCallbacksForEvent, _this).call(_this, 'reconnect_error', [error, ...args]);
      });

      _classPrivateFieldGet(_socket, this).io.on('reconnect_failed', function () {
        log.info('[reconnect_failed]', {
          metadata: {
            socket: _classPrivateFieldGet(_socketId, _this),
            transType: _classPrivateFieldGet(_transportType, _this)
          }
        }); 
        for (var _len9 = arguments.length, args = new Array(_len9), _key9 = 0; _key9 < _len9; _key9++) {
          args[_key9] = arguments[_key9];
        }
        _classPrivateFieldGet(_runCallbacksForEvent, _this).call(_this, 'reconnect_failed', args);
        _classPrivateFieldGet(_runCallbacksForEvent, _this).call(_this, 'dead', [{}]);
      });

      _classPrivateFieldGet(_socket, this).on('update', data => {
        log.debug('[update]: broadcast to subscribers', data, {
          metadata: {
            socket: _classPrivateFieldGet(_socketId, this),
            transType: _classPrivateFieldGet(_transportType, this)
          }
        });
        _classPrivateFieldGet(_runSubscriptionsCallback, this).call(this, data.path, data.value, data.timestamp);
      });
    });
    _classPrivateFieldInitSpec(this, _connect, () => {
      _classPrivateFieldGet(_socket, this).connect();
      log.debug("Socket connecting to ".concat(_classPrivateFieldGet(_data, this).connectUrl));
    });
    _classPrivateFieldInitSpec(this, _emitSubscriptions, subscriptions => {
      for (const subscription of subscriptions) {
        const subscriptionObject = {
          url: subscription.path.url,
          sendInitialUpdate: subscription.sendInitialUpdate,
          backendFallback: subscription.backendFallback
        };
        _classPrivateFieldGet(_socket, this).emit('subscribe', subscriptionObject);
      }
    });
    _classPrivateFieldInitSpec(this, _runSubscriptionsCallback, (url, data, timestamp) => {
      for (const subscription of _classPrivateFieldGet(_subscriptions, this)) {
        if (subscription.path.url.split('?')[0] !== url.split('?')[0]) {
          continue;
        }
        subscription.callback.apply(subscription.context, [data, timestamp]);
      }
    });
    _classPrivateFieldInitSpec(this, _runCallbacksForEvent, (eventType, args) => {
      for (const eventCallback of _classPrivateFieldGet(_eventCallbacks, this)) {
        if (eventCallback.eventType !== eventType) {
          continue;
        }
        eventCallback.callback.apply(eventCallback.context, args);
      }
    });
    _defineProperty(this, "subscribe", (path, callback, context, sendInitialUpdate, autoreconnect, backendFallback) => {
      if (!path || !context) {
        throw new Error('svs.core.Socket.subscribe needs path and context.');
      }
      const subscriptionObject = {
        path: {
          url: path
        },
        callback,
        context,
        sendInitialUpdate: sendInitialUpdate === false ? sendInitialUpdate : true,
        backendFallback: Boolean(backendFallback)
      };

      for (const subscription of _classPrivateFieldGet(_subscriptions, this)) {
        if (subscription.path.url === subscriptionObject.path.url && subscription.callback === subscriptionObject.callback && subscription.context === subscriptionObject.context) {
          return;
        }
      }
      _classPrivateFieldGet(_subscriptions, this).push(subscriptionObject);
      if (!this.isConnected()) {
        _classPrivateFieldGet(_connect, this).call(this);
        return;
      }
      _classPrivateFieldGet(_emitSubscriptions, this).call(this, [subscriptionObject]);
    });
    _defineProperty(this, "unsubscribe", (path, callback) => {
      if (!path || !callback) {
        throw new Error('svs.core.Socket.unsubscribe requires path and callback arguments.');
      }
      const subscriptionIndex = _classPrivateFieldGet(_subscriptions, this).findIndex(subscription => {
        const isCorrectPath = subscription.path.url === path;
        const isLegacyBackboneSubscription = subscription.context.cid === callback.cid;
        const isCorrectCallback = subscription.callback === callback;
        return isCorrectPath && (isLegacyBackboneSubscription || isCorrectCallback);
      });
      if (subscriptionIndex === -1) {
        return;
      }
      _classPrivateFieldGet(_subscriptions, this).splice(subscriptionIndex, 1);
      const remainingSubscriptions = _classPrivateFieldGet(_subscriptions, this).filter(subscription => subscription.path.url === path);
      if (remainingSubscriptions.length !== 0) {
        return;
      }
      _classPrivateFieldGet(_socket, this).emit('unsubscribe', {
        url: path
      });
    });
    _defineProperty(this, "on", (eventType, callback, context) => {
      if (!eventType || !callback) {
        throw new Error('svs.core.Socket.on needs eventType and callback for the callback.');
      }
      if (!this.isConnected()) {
        _classPrivateFieldGet(_connect, this).call(this);
      }
      _classPrivateFieldGet(_eventCallbacks, this).push({
        eventType,
        callback,
        context
      });
    });
    _defineProperty(this, "off", (eventType, callback) => {
      if (!eventType || !callback) {
        throw new Error('svs.core.Socket.off needs eventType and callback for the callback.');
      }
      const callbackIndex = _classPrivateFieldGet(_eventCallbacks, this).findIndex(eventCallback => eventCallback.eventType === eventType && eventCallback.callback === callback);
      if (callbackIndex === -1) {
        return;
      }
      _classPrivateFieldGet(_eventCallbacks, this).splice(callbackIndex, 1);
    });
    _defineProperty(this, "getOptions", () => _classPrivateFieldGet(_socket, this).io.opts);
    _defineProperty(this, "isConnected", () => _classPrivateFieldGet(_socket, this).connected || _classPrivateFieldGet(_socket, this).active);
    _classPrivateFieldSet(_data, this, _data2);
    const RECONNECTION_ATTEMPTS = socketIO.reconnectionAttempts || 5;
    const RECONNECTION_DELAY_MAX = socketIO.reconnectionDelayMax || 5000;

    _classPrivateFieldSet(_socket, this, io(_data2.connectUrl, {
      autoConnect: false,
      closeOnBeforeunload: true,
      transports: ['websocket', 'polling'],
      reconnectionAttempts: RECONNECTION_ATTEMPTS,
      reconnectionDelayMax: RECONNECTION_DELAY_MAX
    }));
    _classPrivateFieldGet(_setupListeners, this).call(this);
  }
}
svs.core.SocketNew = Socket;

 })(window);