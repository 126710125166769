(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/socket/assets/javascripts/init.js') >= 0) return;  svs.modules.push('/components/core/socket/assets/javascripts/init.js');
svs = svs || {};
svs.core = svs.core || {};
svs.core.socket = svs.core.socket || {};
svs.core.socket.data = svs.core.socket.data || {};
svs.core.config = svs.core.config || {};
svs.core.config.data = svs.core.config.data || {};
svs.core.config.data.svsconfig = svs.core.config.data.svsconfig || {};
svs.core.config.data.svsconfig.socketIO = svs.core.config.data.svsconfig
  .socketIO || {
  showAlertDelay: 8000,
  reconnectWait: 500,
  reconnectionAttempts: 5,
  reconnectionDelayMax: 5000
};


 })(window);